import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { TimePicker } from "@material-ui/pickers";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Container,
  Typography,
} from "@material-ui/core";
import { Base } from "../../../../common";
import EasyTapButton from "../EasyTapButton";
import NumberBox from "../NumberBox";
import CompensationTable from "../CompensationTable";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  patchRegistration,
  deleteRegistration,
} from "../../services/registrationService";

import CompensationCalculationHelper from "../../../../common/CompensationCalculationHelper";
import AuthHelperMethods from "../../util/AuthHelperMethods";

// pick a date util library
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/nl";
moment.locale("nl");

const useStyles = makeStyles((theme) => ({
  marginElement: {
    margin: "30px",
  },
}));

const dummyCompensation = {
  hoursDay: 0,
  amountDay: 0,
  hoursNight: 0,
  amountNight: 0,
  hoursHoliday: 0,
  amountHoliday: 0,
  amountCalls: 0,
  totalAmount: 0,
};

const Registration = (props: any) => {
  const classes = useStyles();
  const inputLabel: any = useRef(null);
  //   console.log(props.registration);
  const [startDate, setStartDate] = useState(
    moment(props.registration.start).toDate()
  );
  const [endDate, setEndDate] = useState(
    moment(props.registration.end).toDate()
  );
  const [base, setBase] = useState(props.registration.base);
  const [labelWidth, setLabelWidth] = useState(0);
  const [nbOfCalls, setNbOfCalls] = useState(props.registration.nbOfCalls);
  const [compensation, setCompensation]: [any, any] = useState(
    dummyCompensation
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const authHelperMethods = new AuthHelperMethods();
  const compensationCalculationHelper = new CompensationCalculationHelper(
    authHelperMethods.getConfirm()
  );

  useEffect(() => {
    const calc = () => {
      if (moment(startDate).isBefore(endDate)) {
        setCompensation(
          compensationCalculationHelper.calculateTotalAmount(
            startDate,
            endDate,
            nbOfCalls,
            base
          )
        );
      } else {
        setCompensation(dummyCompensation);
      }
    };
    calc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, base, nbOfCalls]);

  const handleStartDateChange = (value: any) => {
    setStartDate(value);
  };

  const handleEndDateChange = (value: any) => {
    setEndDate(value);
  };

  const handleBaseChange = (event: any) => {
    setBase(event.target.value);
  };

  const decrementNbOfCalls = (event: any) => {
    if (nbOfCalls > 0) {
      setNbOfCalls(nbOfCalls - 1);
    }
  };

  const incrementNbOfCalls = (event: any) => {
    setNbOfCalls(nbOfCalls + 1);
  };

  const _updateRegistration = async () => {
    if (moment(startDate).isBefore(endDate)) {
      await patchRegistration(
        props.registration._id,
        moment(startDate).toDate(),
        moment(endDate).toDate(),
        base,
        nbOfCalls,
        compensation
      );
      setSnackbarOpen(true);
    } else {
      alert("End date is before start date?");
    }
  };

  const _deleteRegistration = async () => {
    await deleteRegistration(props.registration._id);
    setDeleteDialogOpen(false);
    props.back();
  };

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={moment}
        locale="nl"
      >
        <div style={{ textAlign: "center", marginBottom: "30px" }}>
          <Typography component="h1" variant="h4">
            Tijdsregistratie aanpassen
          </Typography>
        </div>
        <div className={classes.marginElement}>
          <TimePicker
            ampm={false}
            label="Start van de shift"
            inputVariant="outlined"
            value={startDate}
            onChange={handleStartDateChange}
            format="HH:mm"
            style={{ width: "100%" }}
          />
        </div>
        <div className={classes.marginElement}>
          <TimePicker
            ampm={false}
            label="Einde van de shift"
            inputVariant="outlined"
            value={endDate}
            onChange={handleEndDateChange}
            format="HH:mm"
            style={{ width: "100%" }}
          />
        </div>
        <div className={classes.marginElement}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel ref={inputLabel} id="base">
              Standplaats
            </InputLabel>
            <Select
              labelId="base"
              id="base-selector"
              value={base}
              labelWidth={labelWidth}
              onChange={handleBaseChange}
            >
              <MenuItem value={Base.Leuven}>Leuven</MenuItem>
              <MenuItem value={Base.Aarschot}>Aarschot</MenuItem>
              <MenuItem value={Base.Tiwi}>Tielt-Winge</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.marginElement}>
          <div>
            <p>
              Aantal ritten (Transporten naar ziekenhuis <strong>OF</strong>{" "}
              Verzorgingen ter plaatse met parameters in ambureg)
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <EasyTapButton label="-" onClick={decrementNbOfCalls} />
              <div>
                <NumberBox label={nbOfCalls} />
              </div>
              <EasyTapButton label="+" onClick={incrementNbOfCalls} />
            </div>
          </div>
        </div>
        <div className={classes.marginElement}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <CompensationTable
              compensation={compensation}
              base={base}
              nbOfCalls={nbOfCalls}
            />
          </div>
        </div>
        <div className={classes.marginElement} style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<SaveIcon />}
            fullWidth={true}
            onClick={_updateRegistration}
          >
            Opslaan
          </Button>
        </div>
        <div className={classes.marginElement} style={{ marginTop: "20px" }}>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            startIcon={<DeleteIcon />}
            fullWidth={true}
            onClick={() => setDeleteDialogOpen(true)}
          >
            Verwijderen
          </Button>
        </div>
        <div className={classes.marginElement} style={{ marginTop: "20px" }}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<ArrowBackIcon />}
            fullWidth={true}
            onClick={() => props.back()}
          >
            Terug
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          autoHideDuration={6000}
          message="Tijdsregistratie opgeslagen!"
        />
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Ben je zeker?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ben je zeker dat je deze tijdsregistratie wilt verwijderen?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
              Neen
            </Button>
            <Button
              onClick={() => _deleteRegistration()}
              color="primary"
              autoFocus
            >
              Verwijderen
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    </Container>
  );
};

export default Registration;