import moment from "moment";
import { Base } from "./contracts";
import Holidays from "date-holidays";

import * as Sentry from "@sentry/browser";

let hd = new Holidays("BE", "VLG");

let officialHolidays = [
  "Nieuwjaar",
  "Paasmaandag",
  "Dag van de Arbeid",
  "O.L.H. Hemelvaart",
  "Pinkstermaandag",
  "Nationale feestdag",
  "O.L.V. Hemelvaart",
  "Allerheiligen",
  "Wapenstilstand",
  "Kerstmis",
];

const factorDay = 6.5;
const factorNight = 7;
const factorHoliday = 9;
const factorCallLeuven = 2;
const factorCallRest = 1;

export default class CompensationCalculationHelper {
  constructor(user?: any) {
    this.user = user;
  }

  private user: any;

  isHoliday = (date: any) => {
    const isHoliday = hd.isHoliday(date);
    if (isHoliday && officialHolidays.includes(isHoliday.name)) {
      return true;
    } else {
      return false;
    }
  };

  isDayAfterHoliday = (date: any) => {
    const yesterday = moment(date).subtract(1, "d");
    if (this.isHoliday(yesterday.toDate())) {
      return true;
    } else {
      return false;
    }
  };

  reportToSentry = (error: any) => {
    Sentry.withScope((scope) => {
      if (this.user) {
        scope.setUser(this.user);
        scope.setLevel(Sentry.Severity.Critical);
      }
      Sentry.captureException(error);
    });
  };

  reportToSentryDebug = (error: any) => {
    console.log(error);
    // Sentry.withScope((scope) => {
    //   if (this.user) {
    //     scope.setLevel(Sentry.Severity.Debug);
    //     scope.setUser(this.user);
    //   }
    //   Sentry.captureException(error);
    // });
    return;
  };

  // Returns object {hoursDay, hoursNight}
  calculateNonHolidayAmountSameDay = (
    startDate: any,
    endDate: any,
    logMessages: string[]
  ) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const startDay = moment(startDate).set({ hour: 8, minute: 0 });
    const endDay = moment(startDate).set({ hour: 20, minute: 0 });

    let result = {
      hoursDay: 0,
      hoursNight: 0,
    };

    logMessages.push(
      "Berekenen van periode zonder feestdag: " +
        start.toLocaleString() +
        " tot " +
        end.toLocaleString() +
        ". "
    );
    if (start.hours() >= startDay.hours() && start.hours() <= endDay.hours()) {
      if (end.hours() >= startDay.hours() && end.hours() <= endDay.hours()) {
        const duration = moment.duration(end.diff(start));
        logMessages.push(
          "Beide vallen in de dag, totaal uren *5,6. Aantal uren aan dagtarief: " +
            duration.asHours() +
            ". Vergoeding: " +
            duration.asHours() * 6.5 +
            ". "
        );
        result.hoursDay = result.hoursDay + duration.asHours();
      } else {
        const dayPart = moment.duration(endDay.diff(start)).asHours() * 6.5;
        const nightPart = moment.duration(end.diff(endDay)).asHours() * 7;
        logMessages.push(
          "Aantal uren aan dagtarief: " +
            moment.duration(endDay.diff(start)).asHours() +
            ". Aantal uren aan nachttarief: " +
            moment.duration(end.diff(endDay)).asHours() +
            ". Bedrag aan dagtarief: " +
            dayPart +
            ". Bedrag aan nachttarief: " +
            nightPart +
            ". "
        );
        result.hoursDay =
          result.hoursDay + moment.duration(endDay.diff(start)).asHours();
        result.hoursNight =
          result.hoursNight + moment.duration(end.diff(endDay)).asHours();
      }
    } else if (start.isBefore(startDay)) {
      if (
        end.isBetween(startDay, endDay) ||
        end.isSame(startDay) ||
        end.isSame(endDay)
      ) {
        const dayPart = moment.duration(end.diff(startDay)).asHours() * 6.5;
        const nightPart = moment.duration(startDay.diff(start)).asHours() * 7;
        logMessages.push(
          "Aantal uren aan nachttarief: " +
            moment.duration(startDay.diff(start)).asHours() +
            ". Aantal uren aan dagtarief: " +
            moment.duration(end.diff(startDay)).asHours() +
            ". Bedrag aan dagtarief: " +
            dayPart +
            ". Bedrag aan nachttarief: " +
            nightPart +
            ". "
        );
        result.hoursDay =
          result.hoursDay + moment.duration(end.diff(startDay)).asHours();
        result.hoursNight =
          result.hoursNight + moment.duration(startDay.diff(start)).asHours();
      } else if (end.isAfter(endDay)) {
        const dayPart = moment.duration(endDay.diff(startDay)).asHours() * 6.5;
        const nightPart =
          (moment.duration(startDay.diff(start)).asHours() +
            moment.duration(end.diff(endDay)).asHours()) *
          7;
        logMessages.push(
          "Aantal uren aan nachttarief: " +
            moment.duration(startDay.diff(start)).asHours() +
            moment.duration(end.diff(endDay)).asHours() +
            ". Aantal uren aan dagtarief: " +
            moment.duration(endDay.diff(startDay)).asHours() +
            ". Bedrag aan dagtarief: " +
            dayPart +
            ". Bedrag aan nachttarief: " +
            nightPart +
            ". "
        );
        result.hoursDay =
          result.hoursDay + moment.duration(endDay.diff(startDay)).asHours();
        result.hoursNight =
          result.hoursNight +
          moment.duration(startDay.diff(start)).asHours() +
          moment.duration(end.diff(endDay)).asHours();
      } else {
        const nightPart = moment.duration(end.diff(start)).asHours() * 7;
        logMessages.push("Bedrag aan nachttarief: " + nightPart + ". ");
        result.hoursNight =
          result.hoursNight + moment.duration(end.diff(start)).asHours();
      }
    } else if (start.isAfter(endDay)) {
      logMessages.push(
        "Beide vallen in de nacht, totaal uren: " +
          moment.duration(end.diff(start)).asHours() +
          ". Totaal: " +
          moment.duration(end.diff(start)).asHours() * 7 +
          ". "
      );
      result.hoursNight =
        result.hoursNight + moment.duration(end.diff(start)).asHours();
    } else {
      logMessages.push("Er zijn duidelijk nog cases niet gecoverd... ");
      this.reportToSentry(new Error("This case shouldn't be reached..."));
      return 0;
    }
    return result;
  };

  // Returns object {hoursDay, hoursNight, hoursHoliday}
  calculateAmountSameDay = (
    startDate: any,
    endDate: any,
    logMessages: string[]
  ) => {
    logMessages.push(
      "Berekenen van vergoeding binnen 1 dag. Start = " +
        startDate +
        ", End = " +
        endDate +
        ". "
    );
    const start = moment(startDate);
    const end = moment(endDate);
    const startHoliday = moment(startDate).set({ hour: 7, minute: 0 });
    const endHoliday = moment(startDate).set({ hour: 7, minute: 0 });

    let result = {
      hoursDay: 0,
      hoursNight: 0,
      hoursHoliday: 0,
    };

    if (this.isHoliday(startDate) && this.isDayAfterHoliday(startDate)) {
      logMessages.push("Het is een feestdag EN een dag na een feestdag. ");
      result.hoursHoliday =
        result.hoursHoliday + moment.duration(end.diff(start)).asHours();
    } else if (this.isHoliday(startDate)) {
      logMessages.push("Het is een feestdag.");
      if (start.isBefore(startHoliday)) {
        const hoursNonHoliday: any = this.calculateNonHolidayAmountSameDay(
          startDate,
          startHoliday.toDate(),
          logMessages
        );
        result.hoursDay = result.hoursDay + hoursNonHoliday.hoursDay;
        result.hoursNight = result.hoursNight + hoursNonHoliday.hoursNight;
        result.hoursHoliday =
          result.hoursHoliday +
          moment.duration(end.diff(startHoliday)).asHours();
      } else {
        result.hoursHoliday =
          result.hoursHoliday + moment.duration(end.diff(start)).asHours();
      }
    } else if (this.isDayAfterHoliday(startDate)) {
      logMessages.push("Het is een dag na een feestdag.");
      if (start.isBefore(endHoliday)) {
        const hoursNonHoliday: any = this.calculateNonHolidayAmountSameDay(
          endHoliday.toDate(),
          end.toDate(),
          logMessages
        );
        result.hoursDay = result.hoursDay + hoursNonHoliday.hoursDay;
        result.hoursNight = result.hoursNight + hoursNonHoliday.hoursNight;
        result.hoursHoliday =
          result.hoursHoliday +
          moment.duration(endHoliday.diff(start)).asHours();
      } else {
        const hoursNonHoliday: any = this.calculateNonHolidayAmountSameDay(
          startDate,
          endDate,
          logMessages
        );
        result.hoursDay = result.hoursDay + hoursNonHoliday.hoursDay;
        result.hoursNight = result.hoursNight + hoursNonHoliday.hoursNight;
      }
    } else {
      const hoursNonHoliday: any = this.calculateNonHolidayAmountSameDay(
        startDate,
        endDate,
        logMessages
      );
      result.hoursDay = result.hoursDay + hoursNonHoliday.hoursDay;
      result.hoursNight = result.hoursNight + hoursNonHoliday.hoursNight;
    }
    return result;
  };

  calculateCallsAmount = (
    nbOfCalls: number,
    base: Base,
    logMessages: string[]
  ) => {
    if (base === Base.Leuven) {
      logMessages.push(
        "Vergoeding van het aantal ritten: " + nbOfCalls * 2 + ". "
      );
      return nbOfCalls * factorCallLeuven;
    } else {
      logMessages.push("Vergoeding van het aantal ritten: " + nbOfCalls + ". ");
      return nbOfCalls * factorCallRest;
    }
  };

  // returns object = {hoursDay, amountDay, hoursNight, amountNight, hoursHoliday, amountHoliday, amountCalls, totalAmount}
  calculateTotalAmount = (
    startDate: any,
    endDate: any,
    nbOfCalls: any,
    base: any
  ) => {
    const logMessages: string[] = [];
    const start = moment(startDate);
    const end = moment(endDate);
    start.set({ second: 0, millisecond: 0 });
    end.set({ second: 0, millisecond: 0 });
    logMessages.push(
      "calculateTotalAmount opgeroepen met startdate = '" +
        startDate +
        "', endDate = '" +
        endDate +
        "', nbOfCalls = '" +
        nbOfCalls +
        "', base = '" +
        base +
        "'."
    );

    let result = {
      hoursDay: 0,
      amountDay: 0,
      hoursNight: 0,
      amountNight: 0,
      hoursHoliday: 0,
      amountHoliday: 0,
      amountCalls: 0,
      totalAmount: 0,
    };

    if (start.isSame(end, "date")) {
      logMessages.push("Start en end zijn beide op dezelfde dag.");
      this.reportToSentryDebug(logMessages);

      const hoursSameDay: any = this.calculateAmountSameDay(
        startDate,
        endDate,
        logMessages
      );
      const amountCalls = this.calculateCallsAmount(
        nbOfCalls,
        base,
        logMessages
      );
      result.hoursDay = result.hoursDay + hoursSameDay.hoursDay;
      result.hoursNight = result.hoursNight + hoursSameDay.hoursNight;
      result.hoursHoliday = result.hoursHoliday + hoursSameDay.hoursHoliday;
      result.amountCalls = amountCalls;
    } else {
      let tempStartDate = moment(start.toDate());
      let tempEndDate = moment(startDate).add(1, "days");
      tempEndDate.set({ hour: 0, minute: 0 });
      while (tempEndDate.isSameOrBefore(end)) {
        logMessages.push(
          "Iteratie: van " +
            tempStartDate.toLocaleString() +
            " tot " +
            tempEndDate.toLocaleString() +
            ". "
        );

        const hoursSameDay: any = this.calculateAmountSameDay(
          tempStartDate.toDate(),
          tempEndDate.toDate(),
          logMessages
        );
        result.hoursDay = result.hoursDay + hoursSameDay.hoursDay;
        result.hoursNight = result.hoursNight + hoursSameDay.hoursNight;
        result.hoursHoliday = result.hoursHoliday + hoursSameDay.hoursHoliday;
        if (tempEndDate.isSame(end)) {
          logMessages.push("tempDate === end, stop de loop. ");
          break;
        } else if (tempEndDate.isSame(end, "date")) {
          logMessages.push(
            "tempDate en end hebben dezelfde dag, zet tempDate gelijk aan end. "
          );
          tempStartDate = tempEndDate;
          tempEndDate = end;
        } else {
          logMessages.push(
            "Huidige tempStartDate: " +
              tempStartDate.toLocaleString() +
              ". Huidige tempEndDate: " +
              tempEndDate.toLocaleString() +
              ". Voeg 1 dag toe aan tempdate en herstart loop. "
          );
          tempStartDate = moment(tempEndDate.toDate());
          tempEndDate = tempEndDate.add(1, "days");
        }
      }
      this.reportToSentryDebug(logMessages);
    }
    result.amountDay = result.hoursDay * factorDay;
    result.amountNight = result.hoursNight * factorNight;
    result.amountHoliday = result.hoursHoliday * factorHoliday;
    result.amountCalls = this.calculateCallsAmount(
      nbOfCalls,
      base,
      logMessages
    );
    result.totalAmount =
      result.amountDay +
      result.amountNight +
      result.amountHoliday +
      result.amountCalls;
    return result;
  };
}
