import React, { useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Container,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { Registration, Base, baseFormatter } from "../../../../common";
import withAuth from "../auth/withAuth";
import { getAllRegistrationsWithProperties } from "../../services/registrationService";
import RegistrationScreen from "./Registration";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { User, UserLevel } from "../../../../common";
import { getUsers } from "../../services/userService";
import { withStyles } from "@material-ui/core/styles";
// @ts-ignore
import ReactExport from "react-export-excel";
import moment from "moment";
import "moment/locale/nl";
import { CloudDownload } from "@material-ui/icons";
moment.locale("nl");

const RegistrationsAdmin = function RegistrationsAdmin() {
  const [base, setBase]: [any, any] = useState("");
  const [users, setUsers]: [User[], any] = useState([]);
  const [user, setUser]: [any, any] = useState("");
  const [registrations, setRegistrations]: [any, any] = useState(null);
  const [activeRegistration, setActiveRegistration]: [
    Registration | null,
    any
  ] = useState(null);
  const [filterFrom, setFilterFrom] = useState(
    moment().startOf("month").toDate()
  );
  const [filterTo, setFilterTo] = useState(moment().toDate());

  const handleUserChange = (event: any) => {
    setUser(event.target.value);
  };

  const handleBaseChange = (event: any) => {
    setBase(event.target.value);
  };

  useEffect(() => {
    fetchUsers();
    setRegs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFrom, filterTo, base, user]);

  const setRegs = async () => {
    setRegistrations(null);
    const data = await getAllRegistrationsWithProperties(
      filterFrom,
      filterTo,
      user,
      base
    );
    setRegistrations(data);
  };

  const fetchUsers = async () => {
    const users = await getUsers();
    setUsers(
      users
        .filter((u) => u.level === UserLevel.USER)
        .sort((u1, u2) =>
          u1.firstName < u2.firstName ? -1 : u2.firstName < u1.firstName ? 1 : 0
        )
    );
  };

  const onChangeFilterFrom = (value: any) => {
    setFilterFrom(value);
  };

  const onChangeFilterTo = (value: any) => {
    setFilterTo(value);
  };

  const calcTotalAmount = (registrations: any): number => {
    let total = 0;
    registrations.forEach(
      (row: any) => (total = total + parseFloat(row.totalAmount))
    );
    return total;
  };

  const back = () => {
    setRegistrations(null);
    setRegs();
    setActiveRegistration(null);
  };

  const LeuvenChip = withStyles({
    root: {
      backgroundColor: "#03A9F4",
      color: "white",
    },
  })(Chip);

  const AarschotChip = withStyles({
    root: {
      backgroundColor: "#FF5722",
      color: "white",
    },
  })(Chip);

  const TiwiChip = withStyles({
    root: {
      backgroundColor: "#8BC34A",
      color: "white",
    },
  })(Chip);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  if (registrations && users) {
    if (activeRegistration) {
      return (
        <RegistrationScreen registration={activeRegistration} back={back} />
      );
    } else {
      if (registrations.length > 0) {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="lg">
              <div style={{ textAlign: "center", marginBottom: "30px" }}>
                <Typography component="h1" variant="h4">
                  Registraties
                </Typography>
              </div>
              <ExpansionPanel style={{ marginBottom: "15px" }}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Filter</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="filterContainer">
                    <div className="filter">
                      <div className="filterField">
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <InputLabel id="user">Gebruiker</InputLabel>
                          <Select
                            labelId="user"
                            id="user-selector"
                            value={user}
                            onChange={handleUserChange}
                          >
                            <MenuItem value="">Geen</MenuItem>
                            {users.map((u: User, index) => {
                              return (
                                <MenuItem key={index} value={u._id}>
                                  {u.firstName +
                                    " " +
                                    u.lastName +
                                    " (" +
                                    u.username +
                                    ")"}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="filterField">
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <InputLabel id="base">Standplaats</InputLabel>
                          <Select
                            labelId="base"
                            id="base-selector"
                            value={base}
                            onChange={handleBaseChange}
                          >
                            <MenuItem value="">Geen</MenuItem>
                            {[Base.Aarschot, Base.Leuven, Base.Tiwi].map(
                              (b: Base) => {
                                return (
                                  <MenuItem key={b} value={b}>
                                    {baseFormatter(b)}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="filter">
                      <div className="filterField">
                        <DateTimePicker
                          ampm={false}
                          label="Van"
                          inputVariant="outlined"
                          value={filterFrom}
                          onChange={onChangeFilterFrom}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="filterField">
                        <DateTimePicker
                          ampm={false}
                          label="Tot"
                          inputVariant="outlined"
                          value={filterTo}
                          onChange={onChangeFilterTo}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="filter">
                      <div className="filterField">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          onClick={() => {
                            setFilterFrom(
                              moment()
                                .subtract(1, "months")
                                .startOf("month")
                                .toDate()
                            );
                            setFilterTo(
                              moment()
                                .subtract(1, "months")
                                .endOf("month")
                                .toDate()
                            );
                          }}
                        >
                          Vorige Maand
                        </Button>
                      </div>
                      <div className="filterField">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          onClick={() => {
                            setFilterFrom(moment().startOf("month").toDate());
                            setFilterTo(moment().endOf("month").toDate());
                          }}
                        >
                          Deze maand
                        </Button>
                      </div>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel style={{ marginBottom: "15px" }}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Exporteren</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="filter">
                    <ExcelFile
                      element={
                        <Button
                          startIcon={<CloudDownload />}
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                        >
                          Download excel export
                        </Button>
                      }
                    >
                      <ExcelSheet data={registrations} name="Registrations">
                        <ExcelColumn
                          label="Gebruiker"
                          value={(row: any) =>
                            row.user.firstName + " " + row.user.lastName
                          }
                        />
                        <ExcelColumn
                          label="Dag"
                          value={(row: any) =>
                            moment(row.start).format("DD/MM/YYYY")
                          }
                        />
                        <ExcelColumn
                          label="Van"
                          value={(row: any) =>
                            moment(row.start).format("HH:mm")
                          }
                        />
                        <ExcelColumn
                          label="Tot"
                          value={(row: any) => moment(row.end).format("HH:mm")}
                        />
                        <ExcelColumn
                          label="Standplaats"
                          value={(row: any) => row.base}
                        />
                        <ExcelColumn
                          label="Aantal ritten"
                          value={(row: any) => row.nbOfCalls}
                        />
                        <ExcelColumn
                          label="Verloning"
                          value={(row: any) => "€" + row.totalAmount}
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Gebruiker</TableCell>
                      <TableCell>Dag</TableCell>
                      <TableCell>Van</TableCell>
                      <TableCell>Tot</TableCell>
                      <TableCell>Standplaats</TableCell>
                      <TableCell>Aantal ritten</TableCell>
                      <TableCell>Verloning</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registrations.map((row: any) => (
                      <TableRow
                        hover
                        style={{ cursor: "pointer" }}
                        key={row._id}
                        onClick={() => setActiveRegistration(row)}
                      >
                        <TableCell>
                          {row.user.firstName +
                            " " +
                            row.user.lastName +
                            " (" +
                            row.user.username +
                            ")"}
                        </TableCell>
                        <TableCell>
                          {moment(row.start).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          {moment(row.start).format("HH:mm")}
                        </TableCell>
                        <TableCell>{moment(row.end).format("HH:mm")}</TableCell>
                        <TableCell>
                          {row.base === "LEUVEN" && (
                            <LeuvenChip
                              size="small"
                              label={baseFormatter(row.base)}
                            />
                          )}
                          {row.base === "AARSCHOT" && (
                            <AarschotChip
                              size="small"
                              label={baseFormatter(row.base)}
                            />
                          )}
                          {row.base === "TIWI" && (
                            <TiwiChip
                              size="small"
                              label={baseFormatter(row.base)}
                            />
                          )}
                        </TableCell>
                        <TableCell>{row.nbOfCalls}</TableCell>
                        <TableCell>&euro; {row.totalAmount}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <strong>Totaal: </strong>
                      </TableCell>
                      <TableCell>
                        &euro; {calcTotalAmount(registrations).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </MuiPickersUtilsProvider>
        );
      } else {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="lg">
              <div style={{ textAlign: "center", marginBottom: "30px" }}>
                <Typography component="h1" variant="h4">
                  Registraties
                </Typography>
              </div>
              <ExpansionPanel style={{ marginBottom: "15px" }}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Filter</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="filterContainer">
                    <div className="filter">
                      <div className="filterField">
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <InputLabel id="user">Gebruiker</InputLabel>
                          <Select
                            labelId="user"
                            id="user-selector"
                            value={user}
                            onChange={handleUserChange}
                          >
                            <MenuItem value={undefined}>Geen</MenuItem>
                            {users.map((u: User, index) => {
                              return (
                                <MenuItem key={index} value={u._id}>
                                  {u.firstName +
                                    " " +
                                    u.lastName +
                                    " (" +
                                    u.username +
                                    ")"}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="filterField">
                        <FormControl variant="filled" style={{ width: "100%" }}>
                          <InputLabel id="base">Standplaats</InputLabel>
                          <Select
                            labelId="base"
                            id="base-selector"
                            value={base}
                            onChange={handleBaseChange}
                          >
                            <MenuItem value={undefined}>Geen</MenuItem>
                            {[Base.Aarschot, Base.Leuven, Base.Tiwi].map(
                              (b: Base) => {
                                return (
                                  <MenuItem key={b} value={b}>
                                    {baseFormatter(b)}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="filter">
                      <div className="filterField">
                        <DateTimePicker
                          ampm={false}
                          label="Van"
                          inputVariant="outlined"
                          value={filterFrom}
                          onChange={onChangeFilterFrom}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="filterField">
                        <DateTimePicker
                          ampm={false}
                          label="Tot"
                          inputVariant="outlined"
                          value={filterTo}
                          onChange={onChangeFilterTo}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="filter">
                      <div className="filterField">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          onClick={() => {
                            setFilterFrom(
                              moment()
                                .subtract(1, "months")
                                .startOf("month")
                                .toDate()
                            );
                            setFilterTo(
                              moment()
                                .subtract(1, "months")
                                .endOf("month")
                                .toDate()
                            );
                          }}
                        >
                          Vorige Maand
                        </Button>
                      </div>
                      <div className="filterField">
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth={true}
                          onClick={() => {
                            setFilterFrom(moment().startOf("month").toDate());
                            setFilterTo(moment().endOf("month").toDate());
                          }}
                        >
                          Deze maand
                        </Button>
                      </div>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <Typography component="h1" variant="h6">
                  Geen registraties in deze periode!
                </Typography>
              </div>
            </Container>
          </MuiPickersUtilsProvider>
        );
      }
    }
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: "20%" }}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
};

export default withAuth(RegistrationsAdmin);
