import React from "react";
import { Card, CardContent, Chip } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { baseFormatter } from "../../../common";
import moment from "moment";
import "moment/locale/nl";
moment.locale("nl");

const LeuvenChip = withStyles({
    root: {
        backgroundColor: '#03A9F4',
        color: "white"
    }
})(Chip);

const AarschotChip = withStyles({
    root: {
        backgroundColor: '#FF5722',
        color: "white"
    }
})(Chip);

const TiwiChip = withStyles({
    root: {
        backgroundColor: '#8BC34A',
        color: "white"
    }
})(Chip);

const getBaseColor = (base: string) => {
    console.log(base);
    if (base === "LEUVEN") {
        return "blue";
    } else if (base === "AARSCHOT") {
        return "red";
    } else if (base === "TIWI") {
        return "green";
    } else {
        return "";
    }
}

const RegistrationCard = (props: any) => {
    return (
        <Card className="gridCard" onClick={props.onClick}>
            <div className={"colorBar " + getBaseColor(props.registration.base)}>&nbsp;</div>
            <CardContent style={{ width: "100%" }}>
                <div className="gridRow horizontalSpacer">
                    <div className="gridColumn">
                        <strong>{moment(props.registration.start).format("DD/MM/YYYY")}<br />
                            {moment(props.registration.start).format("HH:mm")} &rarr; {moment(props.registration.end).format("HH:mm")}</strong>
                    </div>
                    <div className="gridColumn">
                        <div style={{ textAlign: "right" }}>
                            {props.registration.base === "LEUVEN" &&
                                <LeuvenChip
                                    size="small"
                                    label={baseFormatter(props.registration.base)}
                                />
                            }
                            {props.registration.base === "AARSCHOT" &&
                                <AarschotChip
                                    size="small"
                                    label={baseFormatter(props.registration.base)}
                                />
                            }
                            {props.registration.base === "TIWI" &&
                                <TiwiChip
                                    size="small"
                                    label={baseFormatter(props.registration.base)}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="gridRow">
                    <div className="gridColumn center">
                        <big>{props.registration.nbOfCalls}</big>
                    </div>
                    <div className="gridColumn center">
                        <big>&euro; {props.registration.totalAmount}</big>
                    </div>
                </div>
                <div className="gridRow">
                    <div className="gridColumn center">
                        <strong>RITTEN</strong>
                    </div>
                    <div className="gridColumn center">
                        <strong>VERGOEDING</strong>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default RegistrationCard;
