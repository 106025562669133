export interface User {
  _id: string;
  username: string;
  password?: string;
  level: UserLevel;
  firstName: string;
  lastName: string;
  email: string;
  iban: string;
}

export interface Registration {
  _id: number;
  user: User;
  start: Date;
  end: Date;
  base: Base;
  hoursDay: number;
  amountDay: string;
  hoursNight: number;
  amountNight: string;
  hoursHoliday: number;
  amountHoliday: string;
  nbOfCalls: number;
  amountCalls: string;
  totalAmount: string;
}

export enum UserLevel {
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum Base {
  Leuven = "LEUVEN",
  Aarschot = "AARSCHOT",
  Tiwi = "TIWI",
}

export function baseFormatter(base: Base): string {
  switch (base) {
    case Base.Leuven:
      return "Leuven";
    case Base.Aarschot:
      return "Aarschot";
    case Base.Tiwi:
      return "Tielt-Winge";
    default:
      return "PROBLEEM";
  }
}
