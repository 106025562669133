import AuthHelperMethods from "../util/AuthHelperMethods";

const Auth = new AuthHelperMethods();

export const getdocx = (userId: string, month: string, year: string) => {
  return Auth.fetch(
    "/api/expenseReports?userId=" +
      userId +
      "&month=" +
      month +
      "&year=" +
      year,
    {}
  )
    .then((response) => response.blob())
    .then((data) => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([data]));
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `112 Hageland_onkostennota_${month}_${year}.docx`
      );
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};
