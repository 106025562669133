import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import { Base } from "../../../common";

export default function CompensationTable(props: any) {
  const getAmountPerCall = () => {
    if (props.base === Base.Leuven) {
      return 2;
    } else {
      return 1;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p>
        Berekende vergoeding:{" "}
        <strong>&euro; {props.compensation.totalAmount.toFixed(2)}</strong>
      </p>
      <ExpansionPanel style={{ marginBottom: "15px", width: "100%" }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Details van de berekening</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <TableContainer component={Paper}>
            <Table aria-label="Compensation table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Aantal</TableCell>
                  <TableCell>Vergoeding per eenheid</TableCell>
                  <TableCell>Subtotaal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Dag uren</strong>
                  </TableCell>
                  <TableCell>
                    {props.compensation.hoursDay.toFixed(2)}
                  </TableCell>
                  <TableCell>&euro; 6,50</TableCell>
                  <TableCell>
                    &euro; {props.compensation.amountDay.toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Nacht uren</strong>
                  </TableCell>
                  <TableCell>
                    {props.compensation.hoursNight.toFixed(2)}
                  </TableCell>
                  <TableCell>&euro; 7,00</TableCell>
                  <TableCell>
                    &euro; {props.compensation.amountNight.toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Feestdag uren</strong>
                  </TableCell>
                  <TableCell>
                    {props.compensation.hoursHoliday.toFixed(2)}
                  </TableCell>
                  <TableCell>&euro; 9,00</TableCell>
                  <TableCell>
                    &euro; {props.compensation.amountHoliday.toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Ritten</strong>
                  </TableCell>
                  <TableCell>{props.nbOfCalls}</TableCell>
                  <TableCell>&euro; {getAmountPerCall()}</TableCell>
                  <TableCell>
                    &euro; {props.compensation.amountCalls.toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <strong>
                      &euro; {props.compensation.totalAmount.toFixed(2)}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
